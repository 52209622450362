<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import {mapState, mapActions} from "vuex";

export default {
  data() {
    return {
    };
  },
  computed: {
    //展开vuex的store中的计算属性
    ...mapState({
      common: (state) => state.common,
    }),
  },
  created() {
  },
  mounted() {
  },
  methods: {
    //展开vuex中store中的公共函数
    ...mapActions(["getInfoByCode", "getInfoA", "shareId"]),

  },
};
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
